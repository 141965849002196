import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import tent from './images/tent.png'
import torna from './images/torna_reduced.jpg'
import barbecue from './images/barbecue.png'
import nature from './images/nature3.png'
import fire from './images/fire2.png'
import lake from './images/torna_lake2.png'
import campsite_full from './images/campsite_full.png'
import tents from './images/camp_tent.jpeg'
import tentsundershade from './images/tentsonstage_reduced.jpg'
import diningarea from './images/Dining.jpg'
import outerarea from './images/outerarea_reduced.jpg'
import fort from './images/fort.png'
import cottageroom from './images/cottageroom.jpeg'
import roominside from './images/roominside.jpeg'
import swingphoto from './images/20240914_155217_reduced.jpg'
import './ImageCarousel.css'

const ImageCarousel = () => {
    return (
        <div style={{"marginTop":"1%"}}>
            <Carousel class="carousel-indicators">
                <Carousel.Item interval={2500}>
                    <img
                        className="w-100"
                        src={campsite_full}
                        alt="campsite_full"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                    <img
                        className="w-100"
                        src={tentsundershade}
                        alt="tentsundershade"
                    />
                </Carousel.Item> 
                <Carousel.Item interval={2500}>
                    <img
                        className="w-100"
                        src={diningarea}
                        alt="diningarea"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                    <img
                        className="w-100"
                        src={swingphoto}
                        alt="fort"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                    <img
                        className="w-100"
                        src={cottageroom}
                        alt="cottageroom"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                    <img
                        className="w-100"
                        src={tents}
                        alt="tents"
                    />
                </Carousel.Item> 
                <Carousel.Item interval={2500}>
                    <img
                        className="w-100"
                        src={outerarea}
                        alt="outerarea"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                    <img
                        className="w-100"
                        src={roominside}
                        alt="roominside"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                    <img
                        className="w-100"
                        src={torna}
                        alt="torna"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                    <img
                        className="w-100"
                        src={tent}
                        alt="tent"
                    />
                </Carousel.Item>  
                <Carousel.Item interval={2500}>
                    <img
                        className="w-100"
                        src={barbecue}
                        alt="barbecue"
                    />
                </Carousel.Item> 
                <Carousel.Item interval={2500}>
                    <img
                        className="w-100"
                        src={nature}
                        alt="nature"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                    <img
                        className="w-100"
                        src={fire}
                        alt="fire"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                    <img
                        className="w-100"
                        src={fort}
                        alt="fort"
                    />
                </Carousel.Item>  
                <Carousel.Item interval={2500}>
                    <img
                        className="w-100"
                        src={lake}
                        alt="lake"
                    />
                </Carousel.Item>    
            </Carousel>            
        </div>
    )
}

export default ImageCarousel