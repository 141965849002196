import React from 'react'
import './QuoteList.css'
import { Card } from 'react-bootstrap'

const QuoteList = () => {
    return (
        <div>
        <div className="quoteContainer">
            {/* className="quoteContainer" */}
            <Card className="subQuote">
                <Card.Body>
                    <Card.Title className="cardTitle">
                        <h5 className="guestCount">
                            Camping essentials 
                        </h5>
                        <h3 className="price">Rs.1199
                            <span className="period"> /night/head</span>                            
                        </h3>
                        <hr/>
                        <p className="duration">
                            Check-in : 5 pm
                            <br/>
                            Check-out : 10 am
                        </p>
                    </Card.Title>
                    <hr/>
                    <ul>
                        <li>
                            Tent or Cabin
                        </li>
                        <li>
                            Blankets 
                        </li>
                        <li>
                            Bonfire
                        </li>
                        <li>
                            Ample lights for the night
                        </li>
                    </ul>                    
                </Card.Body>
            </Card>
            <Card className="subQuote">
                <Card.Body>
                    <Card.Title className="cardTitle">
                        <h5 className="guestCount">
                            Dinner (Local Veg/Non veg) 
                        </h5>
                    </Card.Title>
                    <hr/>
                    <ul>
                        <li>
                            Evening snacks with Tea
                        </li>
                        <hr/>
                        <li>
                            Dinner Vegetarian / Non-Vegetarian Thali
                        </li>
                        <hr/>
                        <li>
                            Breakfast(Poha/Upma) with Tea
                        </li>       
                        <li>
                            Paneer BBQ / Chicken BBQ ( additional 300 Rs, Need to book atleast 1 day advance) 
                        </li>               
                    </ul>
                </Card.Body>
            </Card>
            <Card className="subQuote">
                <Card.Body>
                    <Card.Title className="cardTitle">
                        <h5 className="guestCount">
                            Amenities
                        </h5>
                        <h3 className="price">
                            <span className="period"> At no cost</span>
                        </h3>
                    </Card.Title>
                    <hr/>
                    <ul>
                        <li>
                            Toilets (1 Comode, 1 Indian )
                        </li>
                        <li>
                            Bath (cold/warm water)
                        </li>
                        <hr/>
                        <li>
                            4 Person Swing
                        </li>
                        <hr/>
                        <li>
                            Note: We can setup tents inside roof in rain and in open area in summer.
                        </li>
                        <li>
                            Note: As camping site runs on solar/green energy we encourage campers to bring power banks.
                        </li>
                    </ul>
                </Card.Body>
            </Card>
        </div>
        <form action="https://tornacampsites.com/signup">
            <input 
                className="quoteButton"
                type="submit" 
                value="Register and Enquiry" />
        </form>
        </div>
    )
}

export default QuoteList